import React from "react"
import classnames from "classnames"
import { graphql } from 'gatsby'

import { Layout, SEO, Container, Newsletter } from "../components"
import styles from "./index.module.scss"
import receiverLogoSrc from "../images/buy-now/receiver-2-white-logo@2x.png"
import { pageTypes } from "../types"
import { getContentFromAllFiles } from "../utils/content"

const NewsletterPage = ({ data, pageContext: { locale } }) => {
  const { title, description } = getContentFromAllFiles(data, 'newsletter');
  const siteContent = getContentFromAllFiles(data, 'site');
  return (
    <Layout className={styles.buyNow} locale={locale} content={siteContent}>
      <SEO title={`${title} | Receiver 2`} description={description} path="/newsletter/" lang={locale} />
      <Container className={classnames(styles.container, styles.buynowContainer)}>
        <div className={styles.intro}>
          <img src={receiverLogoSrc} alt="Receiver 2 logo" className={styles.buyNowLogo} />
          <div className={styles.newsletter}>
            <Newsletter {...siteContent.newsletter} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query newsLetterPageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              description
            }
            ...Site
          }
        }
      }
    }
  }
`

NewsletterPage.propTypes = pageTypes

export default NewsletterPage
